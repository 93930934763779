/* eslint-disable jsx-a11y/iframe-has-title */
/** @format */

// React
import React from "react";
// local styles
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from '../../../assets/icons/logo.png'
import facebook from "../../../assets/footer/facebook.png";
import instagram from "../../../assets/footer/instagram.png";
import twitter from "../../../assets/footer/twitter.png";
import youtube from "../../../assets/footer/youtube.png";
import { useSelector } from "react-redux";




const Footer = () => {
	const { t } = useTranslation();
	const locale = useSelector(state => state.locale.value.lang)

	return (
		<footer className={styles.container}>
			<div className={styles.wrapper}>
				<section className={styles.sec1}>
					<img className={styles.logo} src={logo} alt='logo' />
					<p>{t('footer.sec1Pragraph')}</p>
					<div className={styles.links}>
						{/* <a href='https://web.facebook.com/azan.morocco?mibextid=avESrC&_rdc=1&_rdr' target="_blank" rel="noreferrer"> */}
						<Link to={`/${locale}/`}>
							<img src={facebook} alt="link" />
							{/* </a> */}
						</Link>
						<Link to={`/${locale}/`}>
							<img src={instagram} alt="link" />
						</Link>
						<Link to={`/${locale}/`}>
							<img src={twitter} alt="link" />
						</Link>
						<Link to={`/${locale}/`}>
							<img src={youtube} alt="link" />
						</Link>
					</div>
				</section>
				<section className={styles.sec2}>
					<h3 className={styles.title}>{t(`footer.sec2Title`)}</h3>
					<ul>
						<li>
							<Link to={`${locale}/marble`}>
								{t(`footer.sec2Link3`)}
							</Link>
						</li>
						<li>
							<Link to={`${locale}/granite`}>
								{t(`footer.sec2Link4`)}
							</Link>
						</li>
					</ul>
				</section>
				<section className={styles.sec3}>
					<h3 className={styles.title}>{t('footer.sec3Title')}</h3>
					<div style={{ width: '100%' }}>
						<iframe
							width="100%"
							height="170"
							frameborder="0"
							scrolling="no"
							marginheight="0"
							marginwidth="0"
							src="https://maps.google.com/maps?width=100%25&amp;height=200&amp;hl=en&amp;q=%D8%B7%D8%B1%D9%8A%D9%82%20%D8%B3%D9%8A%D8%AF%D9%8A%20%D8%B1%D8%AD%D8%A7%D9%84%D8%8C%20%D8%AD%D8%AF%20%D8%A7%D9%84%D8%B3%D9%88%D8%A7%D9%84%D9%85%2026402+(My%20Business%20Name)&amp;t=k&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
						</iframe>
					</div>
					<div className={styles.links}>
						{/* <a href='https://web.facebook.com/azan.morocco?mibextid=avESrC&_rdc=1&_rdr' target="_blank" rel="noreferrer"> */}
						<Link to={`/${locale}/`}>
							<img src={facebook} alt="link" />
							{/* </a> */}
						</Link>
						<Link to={`/${locale}/`}>
							<img src={instagram} alt="link" />
						</Link>
						<Link to={`/${locale}/`}>
							<img src={twitter} alt="link" />
						</Link>
						<Link to={`/${locale}/`}>
							<img src={youtube} alt="link" />
						</Link>
					</div>
				</section>
			</div >
		</footer >
	);
};

export default Footer;

/** @format */

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import styles from "./index.module.scss";

const Navbar = () => {
	const { t } = useTranslation();
	const locale = useSelector(state => state.locale.value.lang)

	return (
		<div className={styles.container}>
			<ul className={styles.links}>
				<li>
					<NavLink to={`/${locale}/`} className={({ isActive }) => (isActive ? `${styles.active}` : `${styles.inActive}`)}>
						{t("navbar.main")}
					</NavLink>
				</li>
				<li>
					<NavLink to={`${locale}/marble`} className={({ isActive }) => (isActive ? `${styles.active}` : `${styles.inActive}`)}>
						{t("navbar.marble")}
					</NavLink>
				</li>
				<li>
					<NavLink to={`${locale}/granite`} className={({ isActive }) => (isActive ? `${styles.active}` : `${styles.inActive}`)}>
						{t("navbar.granite")}
					</NavLink>
				</li>
				<li>
					<NavLink to={`${locale}/contact`} className={({ isActive }) => (isActive ? `${styles.active}` : `${styles.inActive}`)}>
						{t("navbar.contact")}
					</NavLink>
				</li>

			</ul>
		</div>
	);
};

export default Navbar;
